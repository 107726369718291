/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { groupBy } from 'lodash';

import {
  fetchCalculatorList,
  fetchCalculatorReference,
  fetchCalculatorReferenceById,
  fetchCalculatorStep,
  updateCalculatorStep,
  calculateCalculatorResults,
  fetchCalculatorResults,
  fetchLastStepId,
  fetchResultsSummary,
  duplicateCalculation,
} from '../../features/calculators';

export const initialState = {
  calculatorList: null,
  calculatorReference: null,
  calculatorStep: null,
  calculatorResults: null,
  calculatorListStatus: 'idle',
  calculatorResultsStatus: 'idle',
  calculatorListError: undefined,
  resultsSummary: null,
  insuredName: null,
  lastStepId: null,
  lastDuplicatedCalculation: null,
};

export const fetchCalculatorsListAsync = createAsyncThunk(
  'calculators/fetch-calculators-list',
  fetchCalculatorList
);
export const fetchCalculatorReferenceAsync = createAsyncThunk(
  'calculators/fetch-calculator-reference',
  fetchCalculatorReference
);
export const fetchCalculatorReferenceByIdAsync = createAsyncThunk(
  'calculators/fetch-calculator-reference-by-id',
  fetchCalculatorReferenceById
);
export const fetchCalculatorStepAsync = createAsyncThunk(
  'calculators/fetch-calculator-step',
  fetchCalculatorStep
);
export const updateCalculatorStepAsync = createAsyncThunk(
  'calculators/update-calculator-step',
  updateCalculatorStep
);
export const caculateCalculatorResultsAsync = createAsyncThunk(
  'calculators/calculate-calculator-results',
  calculateCalculatorResults
);
export const fetchCalculatorResultsAsync = createAsyncThunk(
  'calculators/fetch-calculator-results',
  fetchCalculatorResults
);
export const fetchLastStepIdAsync = createAsyncThunk(
  'calculators/fetch-calculator-last-step-id',
  fetchLastStepId
);
export const fetchResultsSummaryAsync = createAsyncThunk(
  'calculators/fetch-results-summary',
  fetchResultsSummary
);
export const duplicateCalculationAsync = createAsyncThunk(
  'calculators/duplicate-calculation',
  duplicateCalculation
);

export const calculatorSlice = createSlice({
  name: 'calculators',
  initialState,
  reducers: {
    clearCalculatorList: (state) => {
      state.calculatorList = null;
    },
    clearCalculatorStep: (state) => {
      state.calculatorStep = { ...state.calculatorStep, Questions: null };
    },
    clearCalculatorResults: (state) => {
      state.calculatorResults = null;
    },
    clearCalculatorReference: (state) => {
      state.calculatorReference = null;
    },
    clearResultsSummary: (state) => {
      state.resultsSummary = null;
    },
    setInsuredName: (state, { payload }) => {
      state.insuredName = payload;
    },
    clearInsuredName: (state) => {
      state.insuredName = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCalculatorsListAsync.pending, (state) => {
        state.calculatorListStatus = 'loading';
      })
      .addCase(fetchCalculatorsListAsync.fulfilled, (state, action) => {
        state.calculatorListStatus = 'idle';
        state.calculatorList = action.payload;
      })
      .addCase(fetchCalculatorsListAsync.rejected, (state, action) => {
        state.calculatorListStatus = 'erroneous';
        state.calculatorListError =
          action.error.message || 'Failed to fetch calculator list';
      })
      .addCase(fetchCalculatorReferenceAsync.pending, (state) => {
        state.calculatorListStatus = 'loading';
      })
      .addCase(fetchCalculatorReferenceAsync.fulfilled, (state, action) => {
        state.calculatorListStatus = 'idle';
        state.calculatorReference = action.payload;
      })
      .addCase(fetchCalculatorReferenceAsync.rejected, (state, action) => {
        state.calculatorListStatus = 'erroneous';
        state.calculatorListError =
          action.error.message || 'Failed to fetch calculator reference';
      })
      .addCase(fetchCalculatorReferenceByIdAsync.pending, (state) => {
        state.calculatorListStatus = 'loading';
      })
      .addCase(fetchCalculatorReferenceByIdAsync.fulfilled, (state, action) => {
        state.calculatorListStatus = 'idle';
        state.calculatorReference = action.payload;
      })
      .addCase(fetchCalculatorReferenceByIdAsync.rejected, (state, action) => {
        state.calculatorListStatus = 'erroneous';
        state.calculatorListError =
          action.error.message || 'Failed to fetch calculator reference';
      })
      .addCase(fetchCalculatorStepAsync.pending, (state) => {
        state.calculatorListStatus = 'loading';
      })
      .addCase(fetchCalculatorStepAsync.fulfilled, (state, action) => {
        state.calculatorListStatus = 'idle';
        state.calculatorStep = action.payload;
      })
      .addCase(fetchCalculatorStepAsync.rejected, (state, action) => {
        state.calculatorListStatus = 'erroneous';
        state.calculatorListError =
          action.error.message || 'Failed to fetch calculator step';
      })
      .addCase(updateCalculatorStepAsync.pending, (state) => {
        state.calculatorListStatus = 'loading';
      })
      .addCase(updateCalculatorStepAsync.fulfilled, (state) => {
        state.calculatorListStatus = 'idle';
      })
      .addCase(updateCalculatorStepAsync.rejected, (state, action) => {
        state.calculatorListStatus = 'erroneous';
        state.calculatorListError =
          action.error.message || 'Failed to update calculator step';
      })
      .addCase(caculateCalculatorResultsAsync.pending, (state) => {
        state.calculatorListStatus = 'loading';
      })
      .addCase(caculateCalculatorResultsAsync.fulfilled, (state) => {
        state.calculatorListStatus = 'idle';
      })
      .addCase(caculateCalculatorResultsAsync.rejected, (state, action) => {
        state.calculatorListStatus = 'erroneous';
        state.calculatorListError =
          action.error.message || 'Failed to calculate calculator results';
      })
      .addCase(fetchCalculatorResultsAsync.pending, (state) => {
        state.calculatorListStatus = 'loading';
      })
      .addCase(fetchCalculatorResultsAsync.fulfilled, (state, action) => {
        state.calculatorListStatus = 'idle';
        state.calculatorResults = action.payload;
      })
      .addCase(fetchCalculatorResultsAsync.rejected, (state, action) => {
        state.calculatorListStatus = 'erroneous';
        state.calculatorListError =
          action.error.message || 'Failed to fetch calculator results';
      })
      .addCase(fetchLastStepIdAsync.fulfilled, (state, action) => {
        state.lastStepId = action.payload;
      })
      .addCase(fetchLastStepIdAsync.rejected, (state, action) => {
        state.calculatorListStatus = 'erroneous';
        state.calculatorListError =
          action.error.message || 'Failed to fetch calculator last step id';
      })
      .addCase(fetchResultsSummaryAsync.pending, (state) => {
        state.calculatorResultsStatus = 'loading';
      })
      .addCase(fetchResultsSummaryAsync.fulfilled, (state, action) => {
        state.calculatorResultsStatus = 'idle';
        state.resultsSummary = action.payload;
      })
      .addCase(fetchResultsSummaryAsync.rejected, (state, action) => {
        state.calculatorResultsStatus = 'erroneous';
        state.calculatorListError =
          action.error.message || 'Failed to fetch results summary';
      })
      .addCase(duplicateCalculationAsync.pending, (state) => {
        state.calculatorResultsStatus = 'loading';
      })
      .addCase(duplicateCalculationAsync.fulfilled, (state, action) => {
        state.calculatorResultsStatus = 'idle';
        state.lastDuplicatedCalculation = action.payload;
      })
      .addCase(duplicateCalculationAsync.rejected, (state, action) => {
        state.calculatorResultsStatus = 'erroneous';
        state.calculatorListError =
          action.error.message || 'Failed to duplicate calculation';
      });
  },
});

export const selectResultSummaryByCalculator = createSelector(
  (state) => state.calculators.resultsSummary?.ResultsSummaryDetails,
  (state) => state.calculators.resultsSummary?.ClientUpdatedResults,
  (summary, updatedReferencesByClient) => {
    const groupedInsuredName = groupBy(
      summary,
      (result) => result.ParentGroupId
    );
    const groupedResultsByInsuredName = Object.entries(groupedInsuredName).map(
      ([key, value]) => {
        const grouped = groupBy(value, (result) => result.CalculatorName);
        const groupedByCalculator = Object.entries(grouped).map(
          ([calculatorName, value]) => ({
            parentGroupId: key,
            clientName: value[0].InsuredName,
            calculatorName,
            insurer: value[0].Insurer,
            updatedByClient: value
              .filter((ref) =>
                updatedReferencesByClient.includes(ref.ReferenceId)
              )
              .map((ref) => ref.ReferenceId),
            lastUpdate: value.sort(
              (a, b) =>
                new Date(b?.UpdatedDate).getTime() -
                new Date(a?.UpdatedDate).getTime()
            )[0]?.UpdatedDate,
            resultsSummary: value,
          })
        );

        return groupedByCalculator;
      }
    );
    return groupedResultsByInsuredName.flat();
  }
);

export const calculatorSelectors = {
  getCalculatorReference: (state) => state.calculators.calculatorReference,
  getCalculatorStep: (state) => state.calculators.calculatorStep,
  getCalculatorList: (state) => state.calculators.calculatorList,
  getCalculatorListStatus: (state) => state.calculators.calculatorListStatus,
};

export const {
  clearCalculatorList,
  clearCalculatorResults,
  clearCalculatorStep,
  clearCalculatorReference,
  clearInsuredName,
  setInsuredName,
  clearResultsSummary,
} = calculatorSlice.actions;

export default calculatorSlice.reducer;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { fetchCountries } from '../../../../features/calculators';
import './admin-section.less';

const { Option } = Select;

const AdminSection = ({
  selectedCountry,
  setSelectedCountry,
  fetchCalculatorList,
  fetchResultsSummary,
}) => {
  const [countries, setCountries] = useState([]);

  const handleChange = (value) => {
    setSelectedCountry(value);
  };

  useEffect(() => {
    const fetchCountriesAsync = async () => {
      const resCountries = await fetchCountries();
      if (resCountries) setCountries(resCountries);
    };
    fetchCountriesAsync();
  }, []);

  useEffect(() => {
    fetchCalculatorList();
    fetchResultsSummary();
  }, [selectedCountry]);

  return (
    <div className="admin-section">
      <div className="admin-section-title">Admin Section</div>
      <div className="admin-section-select">
        Select Country :
        <Select
          defaultValue={selectedCountry}
          style={{ width: 220, marginLeft: 20 }}
          onChange={handleChange}
        >
          {countries.map((country) => (
            <Option key={country.ID} value={country.ID}>
              {country.CountryName}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default AdminSection;
